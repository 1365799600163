import React from "react"
import { graphql } from "gatsby"
import RawMarkdownPage from "../../templates/raw-markdown-page"

const Press = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  return <RawMarkdownPage frontmatter={frontmatter} html={html} />
}

export const PressEnPageQuery = graphql`
  query PressEnPage {
    markdownRemark(fields: { slug: { eq: "/press/" }, langKey: { eq: "en" } }) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`

export default Press
